import * as TypeGuards from './ProjectV10.guard';
import Project from '@/projects/Project';
import DetailV10, { createSlide, createVideoDomSlide, } from '@/projects/v10/DetailV10';
import { Separator } from '@/projects/node/Node';
import { nanoid } from 'nanoid';
import { getValue } from '@/utils';
export default class ProjectV10 extends Project {
    constructor(project, oldVideoSlideList) {
        super(project);
        this.version = project.version;
        this.v10 = new DetailV10(project.v10, oldVideoSlideList);
    }
    isProject(project) {
        return TypeGuards.isProjectV10(project);
    }
    migrate() {
        this.initTiptap();
        this.migrateTiptap();
        this.migrateSlideProcess();
        this.migrateStyleLabelVersion();
        this.migrateSubtitleSize();
        if (this.isOldVideoProject()) {
            this.media_type = 'audio';
        }
        return this;
    }
    initTiptap() {
        if (this.v10.tiptap.content.length) {
            return;
        }
        const { characters, defaultCharacterId } = this.getCharacters();
        // 비디오 프로젝트인 경우 캐릭터를 복구한다.
        if (this.isOldVideoProject() && this.v10.slide_list) {
            this.v10.tiptap.content = this.v10.slide_list.map(slide => {
                const character = slide.characterId
                    ? characters[slide.characterId]
                    : undefined;
                const characterId = character ? character.actor_id : defaultCharacterId;
                return createParagraph({ actor: characterId });
            });
            return;
        }
        this.v10.tiptap.content = [createParagraph({ actor: defaultCharacterId })];
    }
    migrateTiptap() {
        this.removeDuplicatedIds();
        // 누락된 ID 발급, 비디오 프로젝트는 ID 부여 방식이 달랐기 때문에 항상 덮어쓴다.
        const needToUpdateAllIds = this.isOldVideoProject();
        this.migrateParagraphs(paragraph => ensureId(paragraph, needToUpdateAllIds));
        // 캐릭터 확인
        const { characters, defaultCharacterId } = this.getCharacters();
        this.migrateParagraphs(paragraph => ensureCharacterId(paragraph, characters, defaultCharacterId));
        // Text 사이에 항상 Separator가 존재해야 한다.
        // 하지만 편집기에서 Separator를 지울 수 있기 때문에 이 규칙에 대해 다시 논의해볼 필요가 있고 후에는 Separator를 완전히 제거할 수도 있다.
        this.migrateNodes(ensureTextOrder);
    }
    migrateSlideList() {
        const noNeedMigration = this.v10.additional_data.slide_list_v2.length;
        if (noNeedMigration) {
            return;
        }
        let slides = this.v10.slide_list;
        const defaultCharPosType = this.isShortsProject() ? 'CENTER' : 'LEFT';
        // 비디오 프로젝트는 전체를 다시 부여한다.
        const needToUpdateAllIds = this.isOldVideoProject();
        if (needToUpdateAllIds) {
            slides = this.v10.tiptap.content.map((paragraph, i) => {
                let slide = this.v10.slide_list[i];
                if (!slide) {
                    slide = createSlide({ charPosType: defaultCharPosType });
                }
                slide.slideId = paragraph.attrs.id;
                return slide;
            });
            // Paragraph 정보가 부족하다면 Slide를 복구할 수 없다.
            slides = slides.slice(0, this.v10.tiptap.content.length);
        }
        // 모든 Slide는 slideId를 가지고 있는 것이 보장된다. 부여된 slideId로 추적해 최대한 복구해낸다.
        this.v10.slide_list = this.v10.tiptap.content.map(paragraph => {
            const slideId = paragraph.attrs.id;
            const characterId = paragraph.attrs.actor;
            let slide = slides.find(slide => slide.slideId === slideId);
            if (!slide) {
                slide = createSlide({ slideId, charPosType: defaultCharPosType });
            }
            if (slide.characterId !== paragraph.attrs.actor) {
                slide.characterId = characterId;
                slide.characterSkinType = 'A';
            }
            return slide;
        });
    }
    migrateVideoDomSlideList() {
        const noNeedMigration = this.v10.additional_data.slide_list_v2.length;
        if (noNeedMigration) {
            return;
        }
        let videoDomSlides = this.v10.additional_data.videoDomSlide;
        const defaultCharPosType = this.isShortsProject() ? 'center' : 'left';
        const needToMigrateFromCanvas = !this.v10.additional_data.videoDomSlide.length;
        // videoDomSlide는 존재하나 모든 slideId를 가지고 있지 않은 경우, 단 한차례 마이그레이션이 필요하다고 판단한다.
        const needToUpdateAllIds = videoDomSlides.every(s => !s.slideId);
        if (needToUpdateAllIds) {
            const slideList = this.v10.slide_list;
            videoDomSlides = slideList.map((slide, i) => {
                const videoDomSlide = this.v10.additional_data.videoDomSlide[i] || {};
                return createVideoDomSlide(Object.assign(Object.assign({}, this.v10.additional_data.videoDomSlide[i]), { slideId: slide.slideId, children: needToMigrateFromCanvas
                        ? getChildren(slide)
                        : videoDomSlide.children }));
            });
            // Slide 정보가 부족하다면 VideoDomSlide를 복구할 수 없다.
            videoDomSlides = videoDomSlides.slice(0, slideList.length);
        }
        // 모든 videoDomSlide는 slideId를 가지고 있는 것이 보장된다. 부여된 slideId로 추적해 최대한 복구해낸다.
        this.v10.additional_data.videoDomSlide = this.v10.slide_list.map(slide => {
            var _a, _b, _c;
            let videoDomSlide = videoDomSlides.find(videoDomSlide => videoDomSlide.slideId === slide.slideId);
            videoDomSlide = createVideoDomSlide(Object.assign(Object.assign({}, videoDomSlide), { slideId: slide.slideId, characterId: slide.characterId, characterSkinType: slide.characterSkinType, characterPosition: ((_a = slide.charPosType) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ||
                    defaultCharPosType, characterScale: slide.charPercent * 0.01, characterFlip: slide.charTransFlag, characterVisibility: slide.charFlag, characterBuildInAnimation: ((_b = slide.charAnimation.buildinType) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ||
                    'none', characterBuildOutAnimation: ((_c = slide.charAnimation.buildoutType) === null || _c === void 0 ? void 0 : _c.toLowerCase()) ||
                    'none', transition: slide.transitionType !== 'none' ? slide.transitionType : null, style: Object.assign(Object.assign({}, videoDomSlide === null || videoDomSlide === void 0 ? void 0 : videoDomSlide.style), { backgroundColor: slide.chromakey }) }));
            return videoDomSlide;
        });
    }
    migrateStyleLabelVersion() {
        var _a;
        const styleLabelVersions = {};
        const { characters } = this.getCharacters();
        for (const [characterId, character] of Object.entries(characters)) {
            let styleLabelVersion = this.v10.style_label_version_list[characterId];
            // 사용 중이던게 없으면 최신 버전을 사용
            if (!styleLabelVersion) {
                const { style_label_v2 } = character;
                styleLabelVersion = (_a = style_label_v2[style_label_v2.length - 1]) === null || _a === void 0 ? void 0 : _a.name;
            }
            styleLabelVersions[characterId] = getValue(styleLabelVersion, '');
        }
        this.v10.style_label_version_list = styleLabelVersions;
    }
    removeDuplicatedIds() {
        const ids = new Set();
        this.v10.tiptap.content.forEach(paragraph => {
            const id = paragraph.attrs.id;
            if (ids.has(id)) {
                paragraph.attrs.id = '';
            }
            else {
                ids.add(id);
            }
            paragraph.content.forEach(node => {
                if (node.type === 'separator') {
                    return;
                }
                const text = node;
                const id = text.marks[0].attrs.id;
                if (ids.has(id)) {
                    text.marks[0].attrs.id = '';
                }
                else {
                    ids.add(id);
                }
            });
        });
    }
    migrateParagraphs(callback) {
        const paragraphs = this.v10.tiptap.content.map(paragraph => callback(paragraph));
        this.v10.tiptap = Object.assign(Object.assign({}, this.v10.tiptap), { content: paragraphs });
    }
    migrateNodes(callback) {
        this.migrateParagraphs(paragraph => {
            const nodes = paragraph.content.flatMap(node => callback(node));
            return Object.assign(Object.assign({}, paragraph), { content: nodes });
        });
    }
    getCharacters() {
        let defaultCharacterId = '';
        const characters = {};
        const characterIds = new Set(this.v10.tiptap.content.map(paragraph => paragraph.attrs.actor));
        // 비디오 프로젝트는 Paragraph가 없고 Slide 정보만 있을 수 있다.
        if (!characterIds.size && this.isOldVideoProject()) {
            this.v10.slide_list.forEach(slide => {
                if (!slide.characterId) {
                    return;
                }
                characterIds.add(slide.characterId);
            });
        }
        for (const id of characterIds) {
            const character = ProjectV10.characters.find(character => character.actor_id === id);
            if (!character) {
                continue;
            }
            if (!defaultCharacterId) {
                defaultCharacterId = id;
            }
            characters[id] = character;
        }
        if (!defaultCharacterId && ProjectV10.candidateCharacters.length) {
            defaultCharacterId = ProjectV10.candidateCharacters[0].actor_id;
            characters[defaultCharacterId] = ProjectV10.candidateCharacters[0];
        }
        return { characters, defaultCharacterId };
    }
    migrateSlideV2() {
        const hasSlideListV2 = this.v10.additional_data.slide_list_v2.length;
        const hasSubtitleMap = this.v10.additional_data.slide_list_v2.every(({ subtitleMap }) => subtitleMap);
        const noNeedMigration = hasSlideListV2 && hasSubtitleMap;
        if (noNeedMigration) {
            return;
        }
        let slides = this.v10.slide_list;
        let videoDomSlides = this.v10.additional_data.videoDomSlide;
        // migrate slides and videoDomSlides to slide_list_v2
        const slideListV2 = videoDomSlides.map(videoDomSlide => {
            const slide = slides.find(slide => slide.slideId === videoDomSlide.slideId);
            return Object.assign(Object.assign({}, videoDomSlide), { backgroundColor: (slide === null || slide === void 0 ? void 0 : slide.chromakey) || '#00000000', subtitleList: (slide === null || slide === void 0 ? void 0 : slide.subtitleList) || [], subtitleMap: {} });
        });
        this.v10.additional_data.slide_list_v2 = slideListV2;
        this.v10.slide_list = [];
        this.v10.additional_data.videoDomSlide = [];
    }
    migrateToSubtitleMap() {
        this.v10.additional_data.slide_list_v2.forEach((slide, slideIndex) => {
            const noNeedMigration = !slide.subtitleList.length && slide.speakTextVisibility;
            if (noNeedMigration) {
                return;
            }
            const paragraph = this.v10.tiptap.content[slideIndex];
            if (!paragraph) {
                return;
            }
            const queryList = paragraph.content.filter(node => { var _a; return node.type === 'text' && ((_a = node.text) === null || _a === void 0 ? void 0 : _a.replace(/\s/g, '').length); });
            const queryIdList = queryList.map(query => query.marks[0].attrs.id);
            const subtitleMap = queryIdList.reduce((subtitleMap, queryId, queryIndex) => {
                const subtitle = {};
                subtitleMap[queryId] = subtitle;
                subtitle.invisible = !slide.speakTextVisibility;
                const legacySubtitle = slide.subtitleList[queryIndex];
                if (!legacySubtitle) {
                    return subtitleMap;
                }
                const { text: subtitleText, originalText } = legacySubtitle;
                const isSameWithOriginal = subtitleText === originalText;
                if (!isSameWithOriginal) {
                    subtitle.text = subtitleText;
                }
                return subtitleMap;
            }, {});
            slide.subtitleMap = subtitleMap;
            slide.subtitleList = [];
            slide.speakTextVisibility = true;
        });
    }
    // NOTE: Slide가 Tiptap Paragraph 개수보다 적으면 그만큼 빈 Slides를 slideList에 붙이고, 더 길면 자른다.
    correctSlideListWithTiptap() {
        const slideList = this.v10.additional_data.slide_list_v2;
        const tiptapContent = this.v10.tiptap.content;
        const defaultCharPosType = this.isShortsProject() ? 'center' : 'left';
        const emptySlides = tiptapContent
            .slice(slideList.length)
            .map(({ attrs: { actor, id } }) => (Object.assign(Object.assign({}, createVideoDomSlide({
            slideId: id,
            characterPosition: defaultCharPosType,
            characterId: actor,
            style: { width: '100%', height: '100%', backgroundColor: '#ffffff' },
        })), { subtitleMap: {}, subtitleList: [], backgroundColor: '#00000000' })));
        this.v10.additional_data.slide_list_v2 = [
            ...slideList,
            ...emptySlides,
        ].slice(0, tiptapContent.length);
    }
    // NOTE: slideList와 tiptap paragraph의 id를 동일하게 맞춘다.
    // 이는 기존 마이그레이션 로직에서도 index를 기준으로 동일하게 맞추기에 동일하게 적용한다.
    correctSlideIdWithTiptap() {
        const slideList = this.v10.additional_data.slide_list_v2;
        const tiptapContent = this.v10.tiptap.content;
        this.v10.additional_data.slide_list_v2 = slideList.map((slide, i) => {
            const paragraph = tiptapContent[i];
            if (!paragraph) {
                return slide;
            }
            slide.slideId = paragraph.attrs.id;
            return slide;
        });
    }
    // NOTE: getChildren() 함수에서 id를 발급하지 않았던 것을 수정한다.
    correctAssetId() {
        const slideList = this.v10.additional_data.slide_list_v2;
        this.v10.additional_data.slide_list_v2 = slideList.map(slide => {
            const children = slide.children.map(child => {
                if (!child.id) {
                    child.id = nanoid();
                }
                return child;
            });
            return Object.assign(Object.assign({}, slide), { children });
        });
    }
    migrateSlideProcess() {
        this.migrateSlideList(); // slideList v0
        this.migrateVideoDomSlideList(); // merge(v0, v1)
        this.migrateSlideV2(); // merge(v1, v2)
        this.migrateToSubtitleMap();
        this.correctSlideListWithTiptap();
        this.correctSlideIdWithTiptap();
        this.correctAssetId();
    }
    migrateSubtitleSize() {
        // @ts-ignore
        if (this.subtitle.size === 'normal') {
            this.subtitle.size = 'medium';
        }
    }
}
const getChildren = (slide) => {
    let canvasData = [];
    try {
        canvasData = JSON.parse(slide.canvasData);
    }
    catch (_a) {
        return [];
    }
    if (!Array.isArray(canvasData)) {
        return [];
    }
    return canvasData.map(({ attrs }) => ({
        type: 'image',
        assetId: attrs.imageId,
        style: attrsToStyleMap(attrs),
        id: nanoid(),
    }));
};
const VIDEO_DOM_WIDTH = 1920;
const LEGACY_VIDEO_CANVAS_WIDTH = 1280;
const RESOLUTION = VIDEO_DOM_WIDTH / LEGACY_VIDEO_CANVAS_WIDTH;
export const withResolution = (value) => value ? RESOLUTION * value : 0;
const attrsToStyleMap = ({ scaleX = 1, scaleY = 1, rotation = 0, x = 0, y = 0, width = 0, height = 0, }) => ({
    width: `${withResolution(width) * scaleX}px`,
    height: `${withResolution(height) * scaleY}px`,
    transform: [
        `translate(${withResolution(x)}px, ${withResolution(y)}px)`,
        `rotate(${rotation}deg)`,
    ].join(' '),
});
const ensureId = (paragraph, force = false) => {
    // id가 없으면 발급한다.
    if (!paragraph.attrs.id || force) {
        paragraph.attrs.id = nanoid();
    }
    paragraph.content.forEach(node => {
        if (node.type === 'separator') {
            return;
        }
        const text = node;
        if (!text.marks[0].attrs.id) {
            text.marks[0].attrs.id = nanoid();
        }
    });
    return paragraph;
};
const ensureCharacterId = (paragraph, characters, defaultCharacterId) => {
    let characterId = paragraph.attrs.actor;
    let content = paragraph.content;
    // 더 이상 사용되지 않는 캐릭터는 언제든지 존재할 수 있으므로 매번 확인해야 한다.
    if (!characters[characterId]) {
        characterId = defaultCharacterId;
        const defaultActor = characters[defaultCharacterId];
        if (defaultActor) {
            content = content.map(content => {
                if (content.type !== 'text' ||
                    !content.marks ||
                    !content.marks.length) {
                    return content;
                }
                const currentAttrs = content.marks[0].attrs;
                const actorVersion = defaultActor.style_label_v2[defaultActor.style_label_v2.length - 1];
                const actorStyles = Object.values(actorVersion.data).flat();
                const isSSFMModel = actorVersion.flags.includes('modeltype-ssfm');
                const style = currentAttrs.style &&
                    typeof currentAttrs.style === 'string' &&
                    actorStyles.includes(currentAttrs.style)
                    ? currentAttrs.style
                    : 'normal-1';
                const styleTag = currentAttrs.styleTag && defaultActor.flags.includes('styletag')
                    ? currentAttrs.styleTag
                    : '';
                const speed = currentAttrs.speed &&
                    !isSSFMModel &&
                    defaultActor.tuning.includes('speed')
                    ? currentAttrs.speed
                    : 0;
                const customSpeed = currentAttrs.customSpeed &&
                    defaultActor.flags.includes('speaking-duration')
                    ? currentAttrs.customSpeed
                    : 0;
                const lastPitch = currentAttrs.lastPitch &&
                    !isSSFMModel &&
                    defaultActor.flags.includes('last-pitch')
                    ? currentAttrs.lastPitch
                    : null;
                return Object.assign(Object.assign({}, content), { marks: [
                        Object.assign(Object.assign({}, content.marks[0]), { attrs: Object.assign(Object.assign({}, currentAttrs), { style,
                                styleTag,
                                speed,
                                customSpeed,
                                lastPitch }) }),
                    ] });
            });
        }
    }
    return Object.assign(Object.assign({}, paragraph), { attrs: Object.assign(Object.assign({}, paragraph.attrs), { actor: characterId }), content });
};
const ensureTextOrder = (node) => {
    if (node.type === 'text' && node.text) {
        return [node, new Separator()];
    }
    else {
        return [];
    }
};
const createParagraph = (attrs) => ({
    type: 'paragraph',
    attrs: {
        id: getValue(attrs.id, nanoid()),
        actor: getValue(attrs.actor, ''),
        rest: getValue(attrs.rest, 0),
    },
    content: [],
});
export { TypeGuards };
