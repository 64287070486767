const Icons = {
    IconSpinner: () => import('@/assets/icons/t-icon-spinner-large.svg?inline'),
    IconSpinner2: () => import('@/assets/icons/t-icon-spinner-more.svg?inline'),
    IconGuide: () => import('@/assets/icons/t-icon-guide.svg?inline'),
    IconBug: () => import('@/assets/icons/error_outline.svg?inline'),
    IconInfoSmall: () => import('@/assets/icons/t-icon-info-small.svg?inline'),
    IconInfo: () => import('@/assets/icons/t-icon-info.svg?inline'),
    IconKeyboard: () => import('@/assets/icons/t-icon-keyboard.svg?inline'),
    IconSearch: () => import('@/assets/icons/t-icon-search.svg?inline'),
    IconSetting: () => import('@/assets/icons/t-icon-setting.svg?inline'),
    IconSettingSmall: () => import('@/assets/icons/t-icon-setting-small.svg?inline'),
    IconEditorSetting: () => import('@/assets/icons/t-icon-editor-settings.svg?inline'),
    IconHistory: () => import('@/assets/icons/t-icon-history.svg?inline'),
    IconRecent: () => import('@/assets/icons/t-icon-recent.svg?inline'),
    IconBookmark: () => import('@/assets/icons/icon_bookmark.svg?inline'),
    IconBookmarkOutline: () => import('@/assets/icons/icon_heart_outline.svg?inline'),
    IconReset: () => import('@/assets/icons/t-icon-reset.svg?inline'),
    IconReset2: () => import('@/assets/icons/t-icon-reset2.svg?inline'),
    IconTimer: () => import('@/assets/icons/t-icon-rest.svg?inline'),
    IconUserCircle: () => import('@/assets/icons/user_circle.svg?inline'),
    IconCircleCheck: () => import('@/assets/icons/t-icon-circle-check.svg?inline'),
    IconSaveDark: () => import('@/assets/icons/save_button.svg?inline'),
    IconDownload: () => import('@/assets/icons/t-icon-download-black.svg?inline'),
    IconDownloadCharacter: () => import('@/assets/icons/t-icon-download-char.svg?inline'),
    IconRename: () => import('@/assets/icons/t-icon-rename.svg?inline'),
    IconNote: () => import('@/assets/icons/icon_note.svg?inline'),
    IconDuplicate: () => import('@/assets/icons/t-icon-duplicate.svg?inline'),
    IconLock: () => import('@/assets/icons/t-icon-lock.svg?inline'),
    IconHomeAlt: () => import('@/assets/icons/home_alt_outline.svg?inline'),
    IconMail: () => import('@/assets/icons/t-icon-mail.svg?inline'),
    IconTutorial: () => import('@/assets/icons/t-icon-tutorial-progress.svg?inline'),
    IconLink: () => import('@/assets/icons/link.svg?inline'),
    IconChat: () => import('@/assets/icons/chat.svg?inline'),
    IconSubtitle: () => import('@/assets/icons/subtitle.svg?inline'),
    IconBulb: () => import('@/assets/icons/t-icon-bulb.svg?inline'),
    IconMedia: () => import('@/assets/icons/media.svg?inline'),
    IconBackgroundColor: () => import('@/assets/icons/t-icon-bucket.svg?inline'),
    IconCharacter: () => import('@/assets/icons/t-icon-character.svg?inline'),
    IconVoice: () => import('@/assets/icons/user_voice.svg?inline'),
    IconHandShaking: () => import('@/assets/icons/t-icon-handshaking.svg?inline'),
    IconOpenLink: () => import('@/assets/icons/t-icon-link.svg?inline'),
    IconChangeCharacter: () => import('@/assets/icons/t-icon-change-char.svg?inline'),
    IconOpen: () => import('@/assets/icons/t-icon-open.svg?inline'),
    IconFolderMove: () => import('@/assets/icons/t-icon-folder-move.svg?inline'),
    IconShare: () => import('@/assets/icons/t-icon-share.svg?inline'),
    IconSubitem: () => import('@/assets/icons/t-icon-subitem.svg?inline'),
    IconCard: () => import('@/assets/icons/credit_card.svg?inline'),
    IconIdCard: () => import('@/assets/icons/id_card.svg?inline'),
    IconDropdown: () => import('@/assets/icons/ico-chevron.svg?inline'),
    IconChevronRight: () => import('@/assets/icons/t-icon-chevron-right.svg?inline'),
    IconBack: () => import('@/assets/icons/chevron_big_left.svg?inline'),
    IconChevronDown: () => import('@/assets/icons/icon-chevron_down.svg?inline'),
    IconChevronLargeRight: () => import('@/assets/icons/t-icon-chevron-large-right.svg?inline'),
    IconChevronLargeLeft: () => import('@/assets/icons/t-icon-chevron-large-left.svg?inline'),
    IconChevronSmallLeft: () => import('@/assets/icons/t-icon-chevron-small-left.svg?inline'),
    IconChevronSmallRight: () => import('@/assets/icons/t-icon-chevron-small-right.svg?inline'),
    IconArrowCircleRight: () => import('@/assets/icons/t-icon-arrow-right-circle.svg?inline'),
    IconArrowRight: () => import('@/assets/icons/t-icon-arrow-right.svg?inline'),
    IconLongLeft: () => import('@/assets/icons/t-icon-long-left.svg?inline'),
    IconLongRight: () => import('@/assets/icons/t-icon-long-right.svg?inline'),
    IconDoubleArrow: () => import('@/assets/icons/double_arrow.svg?inline'),
    IconZoomIn: () => import('./t-icon-zoom-in.svg?inline'),
    IconZoomOut: () => import('./t-icon-zoom-out.svg?inline'),
    IconTrashSmall: () => import('@/assets/icons/t-icon-delete-char-large.svg?inline'),
    IconTrash: () => import('@/assets/icons/t-icon-trash.svg?inline'),
    IconDeleteCharacter: () => import('@/assets/icons/t-icon-delete-char.svg?inline'),
    IconTextFile: () => import('@/assets/icons/file-text.svg?inline'),
    IconSubtitleFile: () => import('@/assets/icons/file-subtitle.svg?inline'),
    IconPdfFile: () => import('@/assets/icons/file-pdf.svg?inline'),
    IconExcelFile: () => import('@/assets/icons/file-excel.svg?inline'),
    IconEbookFile: () => import('@/assets/icons/file-ebook.svg?inline'),
    IconAudioFile: () => import('@/assets/icons/file_audio.svg?inline'),
    IconVideoFile: () => import('@/assets/icons/file_video.svg?inline'),
    IconAudio: () => import('@/assets/icons/icon-audio.svg?inline'),
    IconVideo: () => import('@/assets/icons/icon-video.svg?inline'),
    IconShorts: () => import('@/assets/icons/icon_shorts.svg?inline'),
    IconProject: () => import('@/assets/icons/icon_project.svg?inline'),
    IconBringForward: () => import('@/assets/icons/bring_forward.svg?inline'),
    IconBringToFront: () => import('@/assets/icons/bring_to_front.svg?inline'),
    IconSendToBack: () => import('@/assets/icons/send_to_back.svg?inline'),
    IconSendBackward: () => import('@/assets/icons/send_backward.svg?inline'),
    IconImageAsBackground: () => import('@/assets/icons/image_as_background.svg?inline'),
    IconRedo: () => import('@/assets/icons/redo.svg?inline'),
    IconUndo: () => import('@/assets/icons/undo.svg?inline'),
    IconCopyright: () => import('@/assets/icons/icon_copyright.svg?inline'),
    IconClock: () => import('@/assets/icons/icon_clock.svg?inline'),
    IconStar: () => import('@/assets/icons/icon-star.svg?inline'),
    IconDownloadHistory: () => import('@/assets/icons/t-icon-download_history.svg?inline'),
    IconHamburger: () => import('@/assets/icons/hamburger.svg?inline'),
    IconVolume: () => import('@/assets/icons/t-icon-volume-on.svg?inline'),
    IconVolumeOff: () => import('./t-icon-volume-off.svg?inline'),
    IconTrim: () => import('@/assets/icons/t-icon-cut.svg?inline'),
    IconMove: () => import('@/assets/icons/t-icon-move.svg?inline'),
    IconScore: () => import('@/assets/icons/t-icon-score.svg?inline'),
    IconListCheck: () => import('@/assets/icons/list_check.svg?inline'),
    IconListPlus: () => import('@/assets/icons/t-icon-list-plus.svg?inline'),
    IconImport: () => import('@/assets/icons/icon-import.svg?inline'),
    IconFileEmpty: () => import('@/assets/icons/t-icon-file-empty.svg?inline'),
    IconNewFile: () => import('@/assets/icons/file_new.svg?inline'),
    IconVerticalLength: () => import('@/assets/icons/t-icon-vertical-length.svg?inline'),
    IconReceipt: () => import('@/assets/receip_btn.svg?inline'),
    IconWithAI: () => import('@/assets/icons/t-icon-with-ai.svg?inline'),
    IconAiEditScript: () => import('@/assets/icons/t-icon-ai-edit-script.svg?inline'),
    IconInformationScript: () => import('@/assets/icons/t-icon-information-script.svg?inline'),
    IconPlusSquare: () => import('@/assets/icons/plus_square.svg?inline'),
    IconMessage: () => import('@/assets/icons/message_writing.svg?inline'),
    IconMore: () => import('@/assets/icons/icon_more.svg?inline'),
    IconCopy: () => import('@/assets/icons/copy.svg?inline'),
    IconPaste: () => import('@/assets/icons/paste.svg?inline'),
    IconEdit: () => import('@/assets/icons/t-icon-edit.svg?inline'),
    IconBook: () => import('@/assets/icons/t-icon-file-book.svg?inline'),
    IconBook2: () => import('@/assets/icons/t-icon-book.svg?inline'),
    IconIsometricCube: () => import('@/assets/icons/t-icon-isometric-cube.svg?inline'),
    IconBlock: () => import('./block.svg?inline'),
    IconSharePlus: () => import('@/assets/icons/t-icon-share-plus.svg?inline'),
    IconPlus: () => import('@/assets/icons/add.svg?inline'),
    IconPlusSmall: () => import('@/assets/icons/t-icon-plus.svg?inline'),
    IconCircleDecrease: () => import('@/assets/icons/icon-circle-decrease.svg?inline'),
    IconCircleIncrease: () => import('@/assets/icons/icon-circle-increase.svg?inline'),
    IconResolve: () => import('@/assets/icons/t-icon-resolve.svg?inline'),
    IconResolveChecked: () => import('@/assets/icons/t-icon-resolve-checked.svg?inline'),
    IconCheck: () => import('@/assets/icons/check.svg?inline'),
    IconCheckboxBlack: () => import('@/assets/icons/check_box_black.svg?inline'),
    IconMusic: () => import('@/assets/icons/music.svg?inline'),
    IconWizard: () => import('@/assets/icons/wizard.svg?inline'),
    IconMinusCircleFill: () => import('@/assets/icons/t-icon-minus-circle-fill.svg?inline'),
    IconRegenerate: () => import('@/assets/icons/t-icon-regenerate.svg?inline'),
    IconVerified: () => import('@/assets/icons/t-icon-verified.svg?inline'),
    // playbar
    IconBackward: () => import('@/assets/icons/t-icon-backword.svg?inline'),
    IconPlayPrimary: () => import('@/assets/icons/t-icon-play.svg?inline'),
    IconStop: () => import('@/assets/icons/t-icon-stop.svg?inline'),
    IconForward: () => import('@/assets/icons/t-icon-forward.svg?inline'),
    // TODO: types of warning icons can be reduced
    IconWarning: () => import('@/assets/icons/t-icon-warning.svg?inline'),
    IconWarningSmall: () => import('@/assets/icons/t-icon-warning-small.svg?inline'),
    IconWarningYellowSmall: () => import('@/assets/icons/t-icon-warning-yellow-small.svg?inline'),
    IconError: () => import('@/assets/icons/t-icon-error.svg?inline'),
    // TODO: play/stop icons can be organized
    IconPlayCircle: () => import('@/assets/icons/icon_play_circle.svg?inline'),
    IconStopCircle: () => import('@/assets/icons/icon_stop_circle.svg?inline'),
    IconPlay: () => import('@/assets/icons/icon_play_default.svg?inline'),
    IconPlayFill: () => import('./t-icon-play-fill.svg?inline'),
    IconPauseFill: () => import('./t-icon-pause-fill.svg?inline'),
    IconTrimPlay: () => import('@/assets/icons/icon_trim_play.svg?inline'),
    IconTrimPause: () => import('@/assets/icons/icon_trim_pause.svg?inline'),
    IconPlayCircleLine: () => import('@/assets/icons/t-icon-play-circle-line.svg?inline'),
    IconFirstBackward: () => import('@/assets/icons/t-icon-first-backward.svg?inline'),
    IconStopCirclePrimary: () => import('@/assets/icons/t-icon-stop-circle-primary.svg?inline'),
    IconPlayEmpty: () => import('@/assets/icons/t-icon-play-empty.svg?inline'),
    IconPlayOutline: () => import('@/assets/icons/t-icon-play-outline.svg?inline'),
    IconStopFill: () => import('@/assets/icons/t-icon-stop-fill.svg?inline'),
    IconStopOutline: () => import('@/assets/icons/t-icon-stop-outline.svg?inline'),
    IconVisibilitySmallOn: () => import('@/assets/icons/t-icon-visibility-small-on.svg?inline'),
    IconVisibilitySmallOff: () => import('@/assets/icons/t-icon-visibility-small-off.svg?inline'),
    IconLinkSmallOn: () => import('@/assets/icons/t-icon-link-small-on.svg?inline'),
    IconLinkSmallOff: () => import('@/assets/icons/t-icon-link-small-off.svg?inline'),
    IconCrosshair: () => import('@/assets/icons/t-icon-crosshair.svg?inline'),
    IconChevronUpRounded: () => import('@/assets/icons/t-icon-chevron-up-rounded.svg?inline'),
    IconMinusCircleOutline: () => import('@/assets/icons/minus_circle_outline.svg?inline'),
    IconPlusCircleOutline: () => import('@/assets/icons/plus_circle_outline.svg?inline'),
    IconIpEmpty: () => import('@/assets/icons/t-icon-ip-empty.svg?inline'),
    // FIXME: can be used as img tag
    IconBetaBadge: () => import('@/assets/icons/t-beta-badge.svg?inline'),
    IconGoogleLogo: () => import('@/assets/icons/icon_google_logo.svg?inline'),
    IconFbLogo: () => import('@/assets/icons/icon_fb_logo.svg?inline'),
    IconNaverLogo: () => import('@/assets/icons/icon_naver_logo.svg?inline'),
    IconKakaoLogo: () => import('@/assets/icons/icon_kakao_logo.svg?inline'),
    IconEmailLogo: () => import('@/assets/icons/icon-login-email.svg?inline'),
    IconCloseBig: () => import('@/assets/icons/close_big.svg?inline'),
    IconCloseBigWhite: () => import('@/assets/icons/close_big_white.svg?inline'),
    IconCloseSmallWhite: () => import('@/assets/icons/close_small.svg?inline'),
    IconFade: () => import('@/assets/video/transition/icon/fade.svg?inline'),
    IconFadeBlack: () => import('@/assets/video/transition/icon/fadeblack.svg?inline'),
    IconFadeWhite: () => import('@/assets/video/transition/icon/fadewhite.svg?inline'),
    IconSmoothLeft: () => import('@/assets/video/transition/icon/smoothleft.svg?inline'),
    IconSmoothRight: () => import('@/assets/video/transition/icon/smoothright.svg?inline'),
    IconSmoothUp: () => import('@/assets/video/transition/icon/smoothup.svg?inline'),
    IconSmoothDown: () => import('@/assets/video/transition/icon/smoothdown.svg?inline'),
    IconFreePlan: () => import('@/assets/membership/free_id.svg?inline'),
    IconBasicPlan: () => import('@/assets/membership/basic_id.svg?inline'),
    IconProPlan: () => import('@/assets/membership/pro_id.svg?inline'),
    IconBusinessPlan: () => import('@/assets/membership/business_id.svg?inline'),
    IconEnterprisePlan: () => import('@/assets/membership/enterprise_id.svg?inline'),
    IconTypecastColor: () => import('@/assets/icons/typecast-logo-color.svg?inline'),
    IconTimerLoading: () => import('@/assets/icons/t-icon-loading.svg?inline'),
    IconTimerWaiting: () => import('@/assets/icons/t-icon-waiting.svg?inline'),
    IconPlayVideo: () => import('@/assets/icons/t-icon-play-video.svg?inline'),
    IconCookie: () => import('@/assets/icons/t-icon-cookie.svg?inline'),
    IconCircleWon: () => import('@/assets/icons/circle-won.svg?inline'),
};
export default Icons;
