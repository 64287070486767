import mixpanel from 'mixpanel-browser'
import config from '@/config/config'

export class MixpanelManager {
  static instance

  constructor() {
    // Create a GrowthBook context
    mixpanel.init(config.mixpanel.token, {
      debug: process.env.NODE_ENV === 'development',
      batch_requests: false,
      autocapture: true,
    })
  }

  setIdentify(uid) {
    mixpanel.identify(uid)
  }

  getIdentify() {
    return mixpanel.get_distinct_id()
  }

  setUserData(userData) {
    mixpanel.people.set(userData)
  }

  setUserIncrementData(key, value) {
    mixpanel.people.increment(key, value)
  }

  setSuperProperties(properties) {
    mixpanel.register(properties)
  }

  sendEvent(eventName, properties) {
    if (this.hasSuperProperty()) {
      mixpanel.track(eventName, properties)
    }
  }

  hasSuperProperty() {
    return !!mixpanel.get_property('plan_code')
  }

  getSuperProperties() {
    return {
      ...this.getUserData(),
      plan_method: mixpanel.get_property('plan_method'),
      email_domain: mixpanel.get_property('email_domain'),
      login_provider: mixpanel.get_property('login_provider'),
    }
  }

  getUserData() {
    return {
      anonymous: mixpanel.get_property('anonymous'),
      plan_code: mixpanel.get_property('plan_code'),
      plan_cycle: mixpanel.get_property('plan_cycle'),
      plan_name: mixpanel.get_property('plan_name'),
      plan_price: mixpanel.get_property('plan_price'),
      plan_platform: mixpanel.get_property('plan_platform'),
    }
  }

  /**
   * @returns {MixpanelManager}
   */
  static getInstance() {
    if (!this.instance) {
      this.instance = new MixpanelManager()
    }
    return this.instance
  }
}

export default {
  install(Vue) {
    Vue.prototype.$mixpanel = MixpanelManager.getInstance()
  },
}
