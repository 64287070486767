import store from '@/store'
import {i18n} from '@/i18n'

const getPlanInfo = name => {
  const MAX_CONDITION = 8
  const conditions = Array.from({length: MAX_CONDITION}, (_, i) => i)
    .filter(i => i18n.te(`plan_info.${name}_condition_new_${i}`))
    .map(i => `${name}_condition_new_${i}`)

  return {
    title: `${name}_title`,
    time_content: `${name}_second`,
    price: `${name}_price`,
    conditions,
    name: `${name}_id`,
  }
}

const getDefaultPlanSet = () => ({
  free_id: getPlanInfo('free'),
  basic_id: getPlanInfo('basic'),
  pro_id: getPlanInfo('pro'),
  business_id: getPlanInfo('business'),
  enterprise_id: getPlanInfo('enterprise'),
})

/**
 * parse amount list (array) to object that have their currency as a key
 * @param {Object} amountList [{amount, amount_discount_rate, ..., currency: 'krw'}, {amount, amount_discount_rate, ..., currency: 'usd'}]
 * @returns {Object} {krw: {amount, amount_discount_rate, ..., currency}, usd: {amount, amount_discount_rate, ..., currency}}
 */
const _parseAmountByCurrency = amountList => {
  if (!amountList) {
    return null
  }
  const amount = {}
  amountList.forEach(amountItem => (amount[amountItem.currency] = amountItem))
  return amount
}

/**
 * function: handle the response of '/api/price/v2/subscription'
 * @param {*} planList see jsDoc in backend-api 'getSubscribePlan'
 * @returns {Object} reference: https://www.notion.so/neosapience/Typecast-API-cfa727c9d352458f876024ef7cca2ea8#a1aa5db79dd6428d85f9f769e6bbf205
 * {
 *  "basic_id": {
      name: "basic_id",
      seconds: 3600,
      conditions: Array(2)
      member_limit: null
      period: "yearly"
      price: "basic_price"
      time_content: "basic_second"
      title: "basic_title"
      monthly: {
        package_id: string
        amount: {
          krw: {
            amount
            amount_monthly
            amount_discount_rate
            amount_monthly_discount
          },
          usd: {
            amount
            amount_monthly
            amount_discount_rate
            amount_monthly_discount
          }
        }
      },
      yearly: {
        package_id: string
        amount: Array(2)
      },
 *  }
 * }
 */
const getParsedPlanList = planList => {
  const newPlanList = {
    free_id: {},
    basic_id: {},
    pro_id: {},
    business_id: {},
  }

  planList.forEach(plan => {
    // eslint-disable-next-line camelcase
    const {member_limit} = plan.monthly
    const defaultPlanSet = getDefaultPlanSet()
    delete plan.virtual_actor_seconds
    newPlanList[plan.name] = {
      ...plan,
      ...defaultPlanSet[plan.name],
      member_limit,
      monthly: {
        package_id: plan.monthly.package_id,
        amount: _parseAmountByCurrency(plan.monthly.amount),
      },
      yearly: {
        package_id: plan.yearly.package_id,
        amount: _parseAmountByCurrency(plan.yearly.amount),
      },
    }
  })
  return newPlanList
}

/**
 * 
 * @param {plan, period, currency} 
 * @returns 
 * {
 *  name
    title
    package_id
    seconds
    time_content
    member_limit
    conditions
    period
    currency
    amount
    amount_monthly
    amount_discount_rate
    amount_monthly_discount
    promotion
 * }
 */
function getParsedPlan({plan, period, currency}) {
  // eslint-disable-next-line camelcase
  const {name, title, seconds, time_content, member_limit, conditions} = plan
  const _currency = currency ?? store.getters['typecast/user/paymentCurrency']
  const _period = period ?? store.getters['typecast/user/myPaymentPeriod']
  let amount = plan[_period].amount[_currency]
  if (name === 'free_id') {
    amount = {
      ...plan.monthly.amount.krw,
      currency,
    }
  }

  return {
    name,
    title,
    package_id: plan[_period].package_id,
    seconds,
    time_content,
    member_limit,
    conditions,
    period,
    ...amount,
  }
}

function checkPlanChangeCanBeMade(period, beforePlanName, selectedPlanName) {
  if (period === 'monthly' || beforePlanName === 'free_id') {
    return true
  }
  const NEW_PLAN_LIST = ['basic_id', 'pro_id', 'business_id']
  return NEW_PLAN_LIST.indexOf(beforePlanName) <= NEW_PLAN_LIST.indexOf(selectedPlanName)
}

/**
 * No need to use getParsedPlan, setSelectedPlan!
 * @param {planName} (required)
 * @param {planPeriod} (optional) when undefined, use store's myPaymentPeriod
 */
function setPlanAsSelectedPlan({planName, planPeriod}) {
  const paymentCurrency = store.getters['typecast/user/paymentCurrency']
  const planList = store.getters['payment/planList']
  const period = planPeriod ?? store.getters['typecast/user/myPaymentPeriod']
  const _selectedPlan = getParsedPlan({plan: planList[`${planName}`], period, currency: paymentCurrency})
  store.commit('payment/setSelectedPlan', _selectedPlan)
}

export {
  getDefaultPlanSet,
  getPlanInfo,
  getParsedPlanList,
  getParsedPlan,
  checkPlanChangeCanBeMade,
  setPlanAsSelectedPlan,
}
